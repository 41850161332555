html,
body {
  height: 100%;
  margin: 0px;
  background-color: #000000;
}

* {
  margin: 0px;
  padding: 0px;
}
.container-fluid {
  margin: 0px;
  padding: 0px;
}

.modal-body,
.modal-header,
.modal-footer {
  background-color: black;
}
.modal-header button span {
  color: white;
}

:root {
  --mainTextColor: #e6e6e6;
  --secondTextColor: rgb(66, 66, 66);
  --mainBcolor: #191919;
  --secondBcolor: #3b3b3b;
}

@font-face {
  font-family: Roboto-Black;
  src: url(./font/Roboto/Roboto-Black.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(./font/Roboto/Roboto-Regular.ttf);
}

h1 {
  font-family: Roboto-black;
}
h2,
h3,
p {
  font-family: Roboto-Regular;
}

.clear-both {
  clear: both;
}

.wraper {
  margin: 0 auto;
  width: 90%;
  padding: 50px 0px;
  text-align: center;
}

@media screen and (max-width: 968px) {
  .wraper {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  h1 {
    font-size: 1.2em;
    font-weight: bold;
  }
  h2 {
    font-size: 1.2em;
  }
  h3 {
    font-size: 1em;
  }

  p {
    font-size: 0.6em;
  }
}
