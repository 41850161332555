.Groupecard {
  margin-bottom: 50px;
  width: 100%;
  height: auto;
  text-align: left;
  color: var(--mainTextColor);
  font-family: Roboto-Regular;
}
.maincontent {
  display: flex;
  flex-wrap: wrap;
}
.Groupecard .maintitle {
  text-align: center;
  font-family: Roboto-Black;
  font-size: 2em;
}

.Groupecard .text-content {
  width: 50%;
}
.Groupecard .text-content .fullcontent {
  padding-left: 20px;
}
.Groupecard .text-content ul {
  padding-left: 50px;
}

.maintitle {
  width: 100%;
}

.Groupecard .pic-content {
  width: 50%;
  text-align: center;
}

.Groupecard .pic-content img {
  width: 80%;
  height: auto;
}

@media screen and (max-width: 1000px) {
  .Groupecard .text-content ul {
    padding-left: 20px;
  }
  .Groupecard .text-content,
  .Groupecard .pic-content {
    width: 100%;
  }

  .Groupecard .pic-content {
    display: flex;
    justify-content: center;
  }
}
