.home .services {
    background-color: var(--secondBcolor);
    width: 100%;
}

.home .carte{
    display: inline-block;
}

.home .carte .car-content{
    padding-top: 20px;
}
.home .carte .car-content p{
    margin-top: 20px;
}

.home .nav-link{
    display: inline-block;
    padding:0px;
}