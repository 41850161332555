.member{
    height: auto;
    width: 250px;
    background-color:black;
    text-align: center;
    padding: 20px;
    display: inline-block;
    background-color: black;
    margin: 20px 20px 0px 0px;
    
}

.member .picture{
    border-radius: 50% 50% 50% 50%;

}

.member .info{
    text-align: left;
    font-family: 'Roboto-Regular',Arial, Helvetica, sans-serif;
    font-size: 15px;
    margin-top:20px; 
    color: var(--secondColor);

}

.member .info p{
  margin: 0px;
  color: var(--secondColor);
}

.member p:first-of-type{
    text-align: center;
}


.member .info p span{
    font-size: 20px;
}