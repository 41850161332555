#Services .carte {
  height: 0%;
  padding-top: 10px;
  padding-bottom: 20px;
  background-color: rgba(255, 0, 0, 0);
}

#Services .energie-section {
  color: red;
}
