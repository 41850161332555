.infocard {
  color: var(--mainTextColor);
  font-family: Roboto-Regular;
  text-align: center;
  max-width: 500px;
  display: inline-block;
  padding: 30px;
}

.infocard img {
  height: 60px;
}

.infocard p {
  padding-top: 70px;
  font-size: 1em;
}

@media screen and (max-width: 1000px) {
  .infocard {
    width: 100vw;
    padding: 10px;
  }
}
