.carte * {
  padding: 0px;
  margin: 0px;
}

.carte {
  width: 400px;
  background-color: black;
  text-align: center;
  font-family: Roboto-Regular;
  color: var(--mainTextColor);
  margin-left: 25px;
  margin-right: 25px;
}

.carte .car-image {
  height: 50%;
  box-sizing: border-box;
}

.carte .car-image img {
  height: 100%;
  width: 100%;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.carte .car-image img:hover {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}

.carte .car-content {
  border-top: 3px solid white;
  height: 50%;
  padding: 0px 20px;
}
.carte .car-content h1 {
  height: 20%;
  text-transform: uppercase;
}

.carte .car-content p {
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 15px;
  text-align: left;
  height: 80%;
  line-height: 2em;
}

@media screen and (max-width: 1033px) {
  .carte {
    height: auto;
    width: 100%;
    margin: 0px;
  }

  .carte .car-image img {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
  }
}
