.picture {
  display: inline-block;
  margin: 0px;
  padding: 0px;
}
.picture img {
  height: 200px;
  width: 200px;
}

@media screen and (max-width: 463px) {
  .picture img {
    height: 150px;
    width: 150px;
  }
}
