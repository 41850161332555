#impresum,
#datens {
  width: 100%;
  height: 100%;
  color: var(--mainTextColor);
  background-color: var(--mainBcolor);
  text-align: left;
  padding: 20px;
  overflow: scroll;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: none;
}

#impresum .colseBtn,
#datens .colseBtn {
  float: right;
  height: 30px;
  width: 30px;
  background-color: var(--mainTextColor);
  border: none;
  color: var(--mainBcolor);
  font-weight: bold;
  position: fixed;
  top: 10px;
  right: 25px;
}

#impresum .colseBtn:focus,
#datens .colseBtn:focus {
  outline-width: 0;
}

@media screen and (max-width: 968px) {
  #impresum .colseBtn,
  #datens .colseBtn {
    top: 10px;
    right: 10px;
  }
}
