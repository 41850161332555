header * {
  margin: 0px;
  padding: 0px;
}

header {
  width: 100%;
  padding: 0px 100px 30px 100px;
  background-color: rgba(0, 0, 0);
  color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
}
header #toogle-btn {
  display: none;
}

header #toogle-btn h1 {
  font-size: 3em;
}
header #logo {
  float: left;
}

header #logo img {
  height: 45px;
  width: 45px;
  float: left;
  margin-top: 20px;
}

header #logo h1 {
  font-family: Roboto-Black;
  font-size: 1.4em;
  margin-top: 30px;
  text-transform: uppercase;
  float: left;
  padding-left: 15px;
  color: white;
}

header nav {
  float: right;
  margin-top: 30px;
}

header nav ul {
  list-style: none;
  text-transform: uppercase;
}

header nav ul li {
  display: inline-block;
  vertical-align: top;
}

header nav ul li a {
  font-size: 20px;
  font-family: Roboto-Regular;
  text-decoration: none;
  color: var(--mainTextColor);
}

header nav ul li a:hover {
  color: rgb(161, 161, 161);
}

header nav ul li ul li {
  display: list-item;
}
header nav ul li #energirtechniksublink {
  display: none;
}

#energirtechnik:hover > #energirtechniksublink {
  display: block;
}

.sublink {
  font-size: 90%;
}

/*Vertical Menu*/
#full {
  position: fixed;
  top: 70px;
  left: -250px;
  height: 100%;
  background-color: black;
  width: 250px;
  transform: skewY(50deg);
  z-index: 2;
}

#full a {
  display: block;
  text-decoration: none;
  color: var(--mainTextColor);
  height: 30px;
  padding: 10px 10px 10px 25px;
  font-family: Roboto-Regular;
  font-size: 1em;
}

#full .respo-submenu {
  padding-left: 20px;
}

/*Mobile version*/
@media screen and (max-width: 1030px) {
  header {
    height: 70px;
  }

  header #logo {
    float: right;
    margin-right: 0px;
    padding-top: 0px;
  }
  header #toogle-btn {
    display: inline-block;
  }

  header #logo h1 {
    margin-top: 0px;
    padding-top: 10px;
    padding-left: 5px;
  }
  header #logo img {
    margin-top: 0px;
  }

  header {
    padding: 0px 0px 0px 20px;
  }
  header nav {
    display: none;
  }
}
