#configurator {
  padding: 0px 20px;
  height: 100vh;
  width: 100vh;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80% 80%;
}

#configurator h6 {
  color: var(--mainTextColor);
  font-family: Roboto-Bold;
}

#configurator #container {
  height: 100%;
  width: 100%;
  position: relative;
}
/*Feild css*/

#container .feild1 {
  position: absolute;
  height: 35%;
  width: 35%;
  transform: rotateX(56deg) rotateZ(45deg);
  background-color: rgba(8, 178, 243, 0.2);
  top: 18%;
  left: 33%;
}
#container .feild1:hover {
  background-color: rgba(8, 178, 243, 0.4);
}

#container .hovred1:hover + #container {
  transform: rotateX(10deg);
}

#container .feild2 {
  cursor: pointer;

  position: absolute;
  height: 35%;
  width: 35%;
  transform: rotateX(56deg) rotateZ(44deg) rotateY(90deg);
  background-color: rgba(187, 139, 6, 0.2);
  top: 41%;
  left: 46%;
}
#container .feild2:hover {
  background-color: rgba(187, 139, 6, 0.4);
}

#container .feild3 {
  position: absolute;
  height: 35%;
  width: 35%;
  transform: rotateX(56deg) rotateZ(-44deg) rotateY(90deg);
  background-color: rgba(21, 117, 29, 0.2);
  top: 41%;
  left: 20%;
}

#container .feild3:hover {
  background-color: rgba(21, 117, 29, 0.4);
}

#configurator #container img {
  height: 50px;
  width: 50px;
}

#configurator #container h5 {
  font-family: Roboto-black;
  padding-top: 10px;
  color: var(--mainTextColor);
  font-size: 80%;
}

#configurator #container #top h5,
#configurator #container #bottom h5 {
  width: auto;
}

#configurator #container #top {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

#container #top h6 {
  position: absolute;
  top: 170%;
  left: 15%;
}

#configurator #container #left-top {
  position: absolute;
  top: 34%;
  left: 0%;
  transform: translateY(-50%);

  text-align: right;
}

#configurator #container #left-bottom {
  position: absolute;
  bottom: 13%;
  left: 0%;
  transform: translateY(-50%);
  text-align: right;
}

#configurator #container #bottom {
  position: absolute;
  bottom: 08%;
  left: 50%;
  transform: translateX(-50%);
}

#configurator #container #right-top {
  position: absolute;
  top: 34%;
  right: 0%;
  transform: translateY(-50%);

  text-align: left;
}

#configurator #container #right-bottom {
  position: absolute;
  bottom: 13%;
  right: 0%;
  transform: translateY(-50%);
  text-align: left;
}

#configurator #container #right-bottom h6 {
  position: absolute;
  top: 65%;
  right: 80%;
}

#configurator #container #med {
  cursor: pointer;
  position: absolute;
  right: 50%;
  top: 30%;
  transform: translateX(50%);
}

#configurator #container #sol {
  cursor: pointer;

  position: absolute;
  bottom: 33%;
  right: 35%;
  transform: translateX(50%);
}

#configurator #container #tre {
  cursor: pointer;

  position: absolute;
  bottom: 33%;
  left: 24%;
  transform: translateX(50%);
}
#configurator .sec1,
#configurator .sec2,
#configurator .sec3 {
  visibility: hidden;
}
#configurator #med,
#configurator #sol,
#configurator #tre {
  pointer-events: none;
}

#configurator #container h6 {
  font-family: Roboto-black;
  color: rgb(255, 255, 255);
  text-shadow: black 0px 5px 10px;
}

@media screen and (max-width: 900px) {
  #configurator {
    padding: 10px;
    height: 100vw;
    width: 100vw;
  }
  #configurator #container img {
    height: 50px;
    width: 50px;
  }

  #configurator #container h5 {
    padding-top: 5px;
    width: 90px;
    font-size: 70%;
  }
}

@media screen and (max-width: 700px) {
  #configurator #med,
  #configurator #sol,
  #configurator #tre {
    pointer-events: none;
    font-size: 80%;
  }
}

@media screen and (max-width: 500px) {
  #configurator #med,
  #configurator #sol,
  #configurator #tre {
    pointer-events: none;
    font-size: 50%;
  }
}

@media screen and (max-width: 300px) {
  #configurator #med,
  #configurator #sol,
  #configurator #tre {
    pointer-events: none;
    font-size: 20%;
  }
}
