.contacts{
    background-color: black;
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
}

.contacts .wraper #info,.contacts .wraper #formu{
    max-width: 500px;
    float:  left;

}

.contacts .wraper #formu button{
    background: rgba(0, 0, 0,0);
    color: white;
    padding: 2px 20px 4px 20px;
    border: 1px solid white;
}

.contacts .wraper #formu button:hover{
    background-color:white;
    color: black;
}

.contacts .wraper #info{
    margin-right: 200px;
}

.contacts .wraper #info h1{
    color: #E1EFF2;
}
.contacts .wraper #info p{
    color: white;
    text-align: left;
    line-height: 50px;
    font-family: 'Roboto-Regular', Courier, monospace
}



.contacts .wraper #mapi
{
    background-color: rgb(161, 150, 172);
    width: 100%;
    height: 500px;
    position: relative;
    margin-bottom: 100px;
}

.contacts .wraper #formu .inputs{
    background-color:rgba(0, 0, 0,0);
    width: 100%;
    border : 0px;
    border-bottom: 2px solid rgb(255, 255, 255);
    margin-bottom: 20px;
    padding-left: 10px;
    outline: none; 
    font-family: 'Roboto-Bold', 'Arial Narrow', Arial, sans-serif;
    color: white;
 }


 .contacts .wraper #formu .inputs:focus{
    border-bottom: 2px solid rgb(112, 112, 112);
 }



