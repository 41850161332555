.footer-container {
  width: 100%;
  height: auto;
  background-color: var(--mainBcolor);
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-container #nav .menu {
  display: inline-block;
  margin-right: 100px;
  margin-bottom: 40px;
  font-family: Roboto-Regular;
}

.footer-container #nav .menu ul {
  list-style: none;
}

.footer-container #nav .menu ul li {
  color: var(--mainTextColor);
}

.footer-container #folow-us span {
  font-size: 2em;
  margin-right: 20px;
  color: var(--mainTextColor);
}

.footer-container #nav .menu h3 {
  color: var(--mainTextColor);
  font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
  font-size: 1.4em;
}

.footer-container #copyrigth {
  text-align: left;
  color: var(--mainTextColor);
}

.footer-container .wraper #info {
  text-align: left;
}

.footer-container .wraper #info h1 {
  font-size: 2em;
  color: #e1eff2;
}
.footer-container .wraper #info p {
  color: white;
  text-align: left;
  line-height: 30px;
  font-family: "Roboto-Regular", Courier, monospace;
  width: 100%;
}
