.declaration {
  height: 80x;
  width: 100%;
  padding: 10px;
}

.declaration ul {
  list-style: none;
}

.declaration ul li {
  float: left;
  margin-right: 20px;
  color: white;
  cursor: pointer;
}
