#about {
  padding: 20px 30px;
}

#about section {
  text-align: left;
  color: var(--mainTextColor);
}

#about section h3 {
  border-bottom: solid 2px var(--mainTextColor);
}

#about .team {
  padding-top: 40px;
}
