.triangle {
  height: 160px;
  width: 150px;
  position: relative;
  display: flex;
  color: var(--mainTextColor);
}

.triangle .shapes {
  height: 0px;
  width: 0px;
  position: absolute;
  background-color: transparent;
  border-top: 80px solid transparent;
  border-bottom: 80px solid transparent;
  border-right: 0px solid transparent;
  border-left: 150px solid var(--mainBcolor);
}

.bigtriangle {
  height: 200px;
  width: 170px;
  position: relative;
  display: flex;
  color: var(--mainBcolor);
}

.bigtriangle .bigshapes {
  height: 0px;
  width: 0px;
  position: absolute;
  background-color: transparent;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-right: 0px solid transparent;
  border-left: 170px solid var(--mainTextColor);
}

.triangle .content,
.bigtriangle .content {
  text-align: left;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 5px;
  font-family: Roboto-Regular;
  width: 60%;
  text-transform: uppercase;
}
.triangle .number,
.bigtriangle .number {
  text-align: left;
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  padding-left: 5px;
}

.bigtriangle .number,
.bigtriangle .content h6 {
  font-family: Roboto-black;
  font-size: 1.1em;
}
