#contact-form {
  background-color: black;
  display: inline-block;
  width: 100%;
  margin-top: "90px";
  font-family: Roboto-Regular;
}

#contact-form #btn {
  background: rgba(0, 0, 0, 0);
  color: white;
  padding: 2px 20px 4px 20px;
  border: 1px solid white;
  font-family: Roboto-Regular;
}

#contact-form button:hover {
  background-color: white;
  color: black;
}

#contact-form .inputs {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  border: 0px;
  border-bottom: 2px solid rgb(255, 255, 255);
  margin-bottom: 20px;
  padding-left: 10px;
  outline: none;
  font-family: Roboto-Regular;
  color: white;
}

#contact-form .inputs:focus {
  border-bottom: 2px solid rgb(112, 112, 112);
}
