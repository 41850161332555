.owners {
  text-align: center;
}

.owners h1 {
  color: var(--mainTextColor);
  margin-bottom: 50px;
  font-size: 2em;
}

.owners #owner-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.pictureshow img {
  width: 100%;
  height: auto;
}
