.desc-container {
  padding: 70px 60px 0px 60px;
  width: 100%;
  height: auto;
}

.desc-container h1 {
  color: var(--mainTextColor);
  text-transform: uppercase;
  font-family: Roboto-Black;
  letter-spacing: 5px;
}

.desc-container h2 {
  color: var(--mainTextColor);
  margin-top: 15px;
}

.desc-container p {
  color: var(--mainTextColor);
  margin-top: 30px;
  font-family: Roboto-Regular;
}

.desc-container a {
  text-decoration: none;
  color: var(--mainTextColor);
  font-size: 1em;
  border: 1px var(--mainTextColor);
}

.desc-container #homeLink:hover {
  text-decoration: underline;
}
