.serviceDetailleInv {
  height: auto;
  width: 100%;
  display: block;
  position: relative;
  z-index: 0;
  color: var(--mainTextColor);
  margin-top: 50px;
  padding-bottom: 50px;
  padding-top: 50px;
  font-size: 20px;
  border-left: solid 5px white;
}

.serviceDetailleInv::after {
  content: "";
  background: url(../../imgs/Services/fc.jpeg);
  background-size: cover;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.serviceDetailleInv .sd-content {
  margin-top: 60px;
  text-align: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.serviceDetailleInv .sd-description {
  padding: 0px 50px;
  text-align: center;
}

.serviceDetailleInv .sd-description h1 {
  padding-bottom: 10px;
  font-size: 1.5em;
}

@media screen and (max-width: 1030px) {
  .serviceDetailleInv {
    margin-top: 0px;
    padding-bottom: 0px;
    border-top: 3px dotted rgb(165, 160, 160);
    border-left: none;
  }
  .serviceDetailleInv .sd-content {
    display: block;
  }
}
