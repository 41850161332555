
#for-tallent .carte{
    height: 0%;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: rgba(255, 0, 0, 0);
}

#for-tallent .carte .car-image{
    height: 0%;
}